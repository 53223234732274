import React, {Component} from 'react';
import ReactDOM from "react-dom";
import {Query} from 'react-apollo';
import Form from "react-jsonschema-form";
import {Breadcrumb, BreadcrumbItem, Button, Col, FormGroup, Input, Row} from "reactstrap";
import moment from "moment";
import MaskInput from "react-maskinput";
import 'react-dates/lib/css/_datepicker.css';
import axios from "axios";
import jsonata from "./../../../node_modules/jsonata/jsonata-es5";
import loadable from '@loadable/component';
const jwt = require('jsonwebtoken');
const token = sessionStorage.mstoken ? sessionStorage.mstoken : localStorage.token;
const decodedToken = jwt.decode(token);

import {getBusinessPartnerMiddleQuery, Properties } from "../../queries/Queries";
import ExpiringAlert from "../ExpiringAlert";
import Loading from "../../components/Loading"
import i18n from '../../views/Pages/Login/i18n';
import {  filterMeterPointsByActiveRegisters } from '../../utils/Helpers';

async function importModuleOrFallback() {
    let module;
    try {
        // Attempt to import the specific module
        module = await import(`../../${window.config.consul.CLIENT}/utils/Helpers`);
    } catch (error) {
        // If the specific module is not found, import the default module
        module = await import('../../utils/Helpers');
    }
  
    return module;
}

class Reading extends Component {

    render() {
        const getOrganizationId = decodedToken && decodedToken.organizations ? decodedToken.organizations[0] : '';

        return (
            <Query query={getBusinessPartnerMiddleQuery} variables={{
                orderBy: "name",
                where: `id = '${getOrganizationId}'`
            }}>
                {result => {
                    if (result.loading) return (<Loading/>);
                    const data = result.data.businessPartner;
                    const estateList = data[0].mDMBPEstateList;
                    return (<div className="reading-modal">
                        <FormGroup>
                            <label>{i18n.t('readings.address')}:&nbsp;</label>
                            <Input type="select" value={this.state && this.state.mDMBPEstate.id} onChange={(e) => {
                                this.setState({
                                    mDMBPEstate: estateList.filter(estate => estate.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {mDMEstate: {mDMMeterPointList: []}}),
                                    mDMMeterPoint: {
                                        mDMMeterRegisterList: []
                                    },
                                    meter: {}
                                }, () => this.props.onChange(this.state))
                            }
                            }>
                                <option value="">---</option>
                                {estateList.map(estate => <option
                                    value={estate.id}>{estate.mDMEstate.locationAddress.addressLine1}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.meterPoint')}:&nbsp;</label>
                            <Input type="select" value={this.state && this.state.mDMMeterPoint.id} onChange={(e) => {
                                this.setState({
                                    mDMMeterPoint: this.state.mDMBPEstate.mDMEstate.mDMMeterPointList.filter(meterPoint => meterPoint.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {mDMMeterRegisterList: []})
                                }, () => this.props.onChange(this.state))
                            }}>
                                <option value="">---</option>
                                {this.state && this.state.mDMBPEstate.mDMEstate.mDMMeterPointList.map(meterPoint => <option
                                    value={meterPoint.id}>{meterPoint.name}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.meter')}:&nbsp;</label>
                            <Input type="select" value={this.state && this.state.meter.id}
                                   onChange={(e) => this.setState({meter: this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({meter}) => meter.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {meter: ''}).meter}, () => this.props.onChange(this.state))}>
                                <option value="">---</option>
                                {this.state && this.state.mDMMeterPoint.mDMMeterRegisterList.map(({meter}) => meter).filter((meter, index, self) => {
                                    return self.findIndex(m => m.id === meter.id) === index;
                                }).map(meter => <option value={meter.id}>{meter.serialNumber}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.type')}:&nbsp;</label>
                            <Input type="select" value={this.state && this.state.type}
                                   onChange={(e) => this.setState({...this.state, type: e.target.value}, () => this.props.onChange(this.state))}>
                                <option value="COU_C">Customer</option>
                                <option value="COU_E">Estimated</option>
                            </Input>
                        </FormGroup>
                        {this.state && this.state.type !== "COU_E" && this.state.mDMMeterPoint.mDMMeterRegisterList.filter(({meter}) => meter.id === this.state.meter.id).map(mDMMeterRegister => {
                            const lastReading = mDMMeterRegister.meter.ammMeterReadingList
                                .filter(reading => (reading.customerReadType === "Customer" || reading.customerReadType === "Initial") && reading.product.id === mDMMeterRegister.mDMRegister.product.id)
                                .map(reading => ({
                                    ...reading,
                                    readingdate: moment(reading.readingdate, "YYYY-MM-DDTHH:mm:ssZ")
                                }))
                                .sort((a, b) => b.readingdate.isBefore(a.readingdate) ? -1 : 1)[0];
                            return (<FormGroup>
                                <label>{mDMMeterRegister.mDMRegister.product.name}:&nbsp;</label><br/>
                                <div className="final-reading-box">
                                <span className="final-reading"><label>Final reading</label></span>
                                <MaskInput value={this.state['reading' + mDMMeterRegister.id]}
                                           onChange={(e) => this.setState({
                                               ['reading' + mDMMeterRegister.id]: e.target.value,
                                               ['lastReading' + mDMMeterRegister.id]: lastReading || null
                                           }, () => {this.props.onChange(this.state)})}
                                           alwaysShowMask
                                           maskChar={"0"}
                                           mask={"0".repeat(mDMMeterRegister.meterReadingDigitsCount)}
                                           placeholder="20491"
                                           className={'reading-input'}
                                           style={{width: mDMMeterRegister.meterReadingDigitsCount * 24}}
                                />
                                </div>
                            </FormGroup>)
                        })}
                    </div>);
                }}
            </Query>)
    }

}

class ReadingFromEssence extends Component {

    constructor(arg) {
        super(arg);
        this.state = {
            mDMBPEstate: {id: ''},
            mDMMeterPoint: {id: ''},
            meter: {id: ''},
            type: '',
        };
        this.clearReadingLastReading = this.clearReadingLastReading.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (Object.keys(prevProps.formContext).length !== 0 &&
            this.props.formContext.reading &&
            Object.keys(this.props.formContext.reading).length === 0 &&
            Object.keys(this.props.formContext.reading).length < Object.keys(prevProps.formContext.reading).length) {

            this.clearReadingLastReading();
            this.setState({
                mDMBPEstate: {id: ''},
                mDMMeterPoint: {id: ''},
                meter: {id: ''},
                type: '',
            })
        }
    }

    clearReadingLastReading() {
        this.setState(current => {
            current.mDMBPEstate.site && current.mDMBPEstate.site.meterPoints[0].registers.filter(register => {
                let lastReading = `lastReading${register.id}`;
                let reading = `reading${register.id}`;

                delete current[lastReading];
                delete current[reading];
            });
        })
    }

    filterMeterPoints(value) {
        const meterPoints = this.state.mDMBPEstate.site.meterPoints
            .filter(meterPoint => meterPoint.id === value)
            .reduce((accumulator, currentValue) => {
                accumulator.push(currentValue);
                return accumulator;
            }, []);
        
        return  filterMeterPointsByActiveRegisters(meterPoints);
    }

    render() {
        const getOrganizationId = decodedToken && decodedToken.organizations ? decodedToken.organizations[0] : '';
        const isEnloc = window.config.consul.CLIENT === 'enloc-ssp';

        return (
            <Query query={Properties} variables={
                {
                    "pageSize": 200,
                    "pageNumber": 0,
                    "sort": {
                        "orders": [
                            {
                                "property": "id",
                                "direction": "asc"
                            }
                        ]
                    },
                    "where": null
                }
            }>
                {result => {
                    if (result.loading) return (<Loading/>);
                    const data = result.data.Property;
                    const estateList = data.content;
                    return (<div className="reading-modal">
                        <FormGroup>
                            <label>{i18n.t('readings.address')}:&nbsp;</label>
                            <Input required type="select" value={this.state.mDMBPEstate.id} onChange={(e) => {
                                this.clearReadingLastReading();
                                this.setState(
                                    {mDMBPEstate: estateList
                                        .filter(estate => {
                                            return estate.id === e.target.value
                                        })
                                        .reduce((accumulator, currentValue) => currentValue || accumulator, {mDMEstate: {mDMMeterPointList: []}}),
                                    mDMMeterPoint: {id: ''},
                                    meter: {id: ''},
                                    type: '',
                                },
                                () => {
                                    this.props.onChange(this.state)
                                })
                            }
                            }>
                                {!this.props.formData.mDMBPEstate && <option value="">---</option>}
                                {estateList.map(estate => {
                                    const exp = isEnloc 
                                        ? "$join([$join($filter([street, houseNumber, houseNumberAdd], function($v) {$v != null and $v != ''}), ' '),postalCode & ' ' & city], ', ')"
                                        : "$replace($replace($eval($).(door & ', ' & street & ', ' & countryCode & ', ' & houseNumberAdd & ', ' & streetType & ', ' & dependentStreet & ', ' & doubleDependentCity & ', ' & dependentCity & ', ' & city & ', ' & region & ', ' & postalCode),'null, ',''),', null','')";
                                    const localExpr = jsonata(exp)
                                    const addressValue = isEnloc 
                                        ? localExpr.evaluate(JSON.parse(estate.site.address))
                                        : localExpr.evaluate(estate.site.address);
                                        
                                    return <option value={estate.id}>{addressValue}</option>
                                })}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.meterPoint')}:&nbsp;</label>
                            <Input required type="select" value={this.state.mDMMeterPoint.id} onChange={(e) => {
                                const meterPoint = this.filterMeterPoints(e.target.value);
                                this.clearReadingLastReading();
                                this.setState({
                                    mDMMeterPoint: meterPoint.length > 0 ? meterPoint[0] : {id: ''},
                                    meter: {id: ''},
                                    type: '',
                                },
                                () => {
                                    this.props.onChange(this.state)
                                })
                            }}>
                                <option value="">---</option>
                                {this.state.mDMBPEstate.site && this.state.mDMBPEstate.site.meterPoints.map(meterPoint => <option
                                    value={meterPoint.id}>{meterPoint.identifier}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.meter')}:&nbsp;</label>
                            <Input required type="select" value={this.state.meter.id}
                                   onChange={(e) => {
                                       this.clearReadingLastReading();
                                       this.setState({
                                            meter: this.state.mDMMeterPoint.registers.filter(({meter}) => meter.id === e.target.value).reduce((accumulator, currentValue) => currentValue || accumulator, {meter: ''}).meter,
                                            type: '',
                                       },
                                       () => {
                                            this.props.onChange(this.state)
                                       })
                                   }}>
                                <option value="">---</option>
                                {this.state.mDMMeterPoint.registers && this.state.mDMMeterPoint.registers
                                    .map(({meter}) => meter)
                                    .filter((meter, index, self) => {
                                        return self.findIndex(m => m.id === meter.id) === index;
                                    })
                                    .map(meter => <option value={meter.id}>{meter.serialNumber}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label>{i18n.t('readings.type')}:&nbsp;</label>
                            <Input required type="select" disabled={this.props.formData.mDMBPEstate ? false : true} value={this.state.type}
                                   onChange={(e) => {
                                       this.clearReadingLastReading();
                                           this.setState({
                                               type: e.target.value
                                            },
                                            () => this.props.onChange(this.state))
                                   }}>
                                <option value="">---</option>
                                <option value="COU_C">{i18n.t("readings.customer")}</option>
                                <option value="COU_E">{i18n.t("readings.estimated")}</option>
                            </Input>
                        </FormGroup>
                        {(this.state.type !== "COU_E" && this.state.type !== '' && this.state.mDMMeterPoint.registers) && 
                            this.state.mDMMeterPoint.registers.filter(({meter}) => meter.id === this.state.meter.id).map(mDMMeterRegister => {

                            const lastReading = mDMMeterRegister.readings
                                .filter(reading => (reading.type === "C" || reading.type === "I"))
                                .map(reading => ({
                                    ...reading,
                                    readingdate: moment(reading.readingdate, "YYYY-MM-DDTHH:mm:ssZ")
                                }))
                                .sort((a, b) => b.readingdate.isBefore(a.readingdate) ? -1 : 1)[0];
                            return (<FormGroup>
                                <label>{mDMMeterRegister.rate}:&nbsp;</label><br/>
                                <div className="final-reading-box">
                                    <span className="final-reading"><label>{i18n.t("readings.finalReading")}</label></span>
                                    <MaskInput value={this.state['reading' + mDMMeterRegister.id]}
                                               onChange={(e) => this.setState({
                                                   ['reading' + mDMMeterRegister.id]: e.target.value,
                                                   ['lastReading' + mDMMeterRegister.id]: lastReading || null
                                               }, () => {this.props.onChange(this.state)})}
                                               alwaysShowMask
                                               maskChar={"0"}
                                               mask={"0".repeat(mDMMeterRegister.digits)}
                                               placeholder="20491"
                                               className={'reading-input'}
                                               style={{width: mDMMeterRegister.digits * 24}}
                                    />
                                </div>
                            </FormGroup>)
                        })}
                    </div>);
                }}
            </Query>)
    }

}

const locale = window.config.consul.DEFAULT_LOCALE;

const schema = {
    title: "",
    type: "object",
    required: [
        "moveOutDate",
        "moveOutReason",
        "reading",
    ],
    properties: {
        moveOutDate: {
            type: "string",
            title: "readings.moveOutDate",
            default: moment().format('YYYY-MM-DD'),
        },
        moveOutReason: {
            type: "string",
            title: "readings.moveOutReason",
        },
        reading: {
            type: "object",
        },
        helpText: {
            title: "readings.helpText",
            type: "null",
        },
        customerType: {
            type: "string",
            title: "readings.customerType",
            enum: ["Private", "Company"],
            enumNames: ["readings.domestic", "readings.business"],
        },
        contactEmail: {
            type: "string",
            title: "readings.contactEmail",
        },
        contactPhone: {
            type: "number",
            title: "readings.phone",
        },
        description: {
            type: "string",
            title: "readings.notes",
        }
    },
    dependencies: {
        customerType: {
            oneOf: [
                {
                    properties: {
                        customerType: {
                            enum: ["Company"]
                        },
                        companyName: {
                            "type": "string",
                            "title": "readings.companyName"
                        },
                        firstName: {
                            type: "string",
                            title: "readings.contactFirstName"
                        },
                        lastName: {
                            type: "string",
                            title: "readings.contactLastName",
                        }
                    }
                },
                {
                    properties: {
                        customerType: {
                            enum: ["Private"]
                        },
                        firstName: {
                            type: "string",
                            title: "readings.contactFirstName"
                        },
                        lastName: {
                            type: "string",
                            title: "readings.contactLastName",
                        }
                    }
                }
            ]
        }
    }
}
const uiSchema= {
    moveOutDate: {
        "ui:widget": "date"
    },
    moveOutReason: {
        "ui:widget": "textarea"
    },
    description: {
        "ui:widget": "textarea"
    },
    reading: {
        "ui:field": "readingField"
    },
    helpText: {
        classNames: "helpText"
    },
    "ui:order": ["moveOutDate", "moveOutReason", "reading", "customerTitle", "helpText", "customerType", "companyName", "firstName", "lastName", "contactPhone", "contactEmail", "description"]
}

class MovingOut extends Component {

    constructor(arg) {
        super(arg);
        this.state = {
            buttonStyle: {visibility: "hidden", isLoading: false, period: null}
        }
        this.clearFields = this.clearFields.bind(this);
    }

    clearFields() {
        this.setState({
            buttonStyle: this.state.buttonStyle,
            formContext: {
                moveOutDate: this.state.formContext.moveOutDate,
                reading: {}
            }
        })
    }

    translate(obj) {
        if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
            if (!obj.props) {
                for (let [key, value] of Object.entries(obj)) {
                    if (typeof value === "object" && !Array.isArray(value)) {
                        this.translate(value);
                    } else if (Array.isArray(value)) {
                        if (key === "enumNames") {
                            obj[key] = value.map(e => i18n.t(e));
                        } else {
                            obj[key] = value.map(e => this.translate(e));
                        }
                    } else if (typeof value === "string") {
                        if (key === "title" || key === "description") obj[key] = i18n.t(obj[key]);
                    }
                }
            }

        } else if (obj && Array.isArray(obj)) {

            obj.forEach((value, key) => {
                if (typeof value === 'object' && !Array.isArray(value)) {
                    this.translate(value);
                } else if (Array.isArray(value)) {

                    if (key === "enumNames") {
                        obj[key] = value.map(e => i18n.t(e));
                    } else {
                        obj[key] = value.map(e => this.translate(e));
                    }
                } else if (typeof value === "string") {
                    if (key === "title" || key === "description") obj[key] = i18n.t(obj[key]);
                }
            });
        }

        return obj;
    }

    render() {
        const translatedSchema = this.translate(schema);
        const loadingIcon = this.state.isLoading 
            ? (<span><i style={this.state.buttonStyle} className='fa fa-circle-o-notch fa-spin'></i> </span>) 
            : null;

        return (
            <div>
                <Row className='subheader'>
                    <Col xs={6} md={8}>
                        <h1>{i18n.t('readings.movingOut')}</h1>
                    </Col>
                    <Col xs={6} md={4}>
                        <Breadcrumb>
                            <BreadcrumbItem><i className="icon ion-android-home"></i><a href="#">{i18n.t('readings.home')}</a></BreadcrumbItem>
                            <BreadcrumbItem active>{i18n.t('readings.movingOut')}</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div className="page-cnt col-12 col-md-6">
                    <Form schema={translatedSchema}
                        uiSchema={uiSchema}
                        fields={{readingField: ReadingFromEssence}}
                        formContext={this.state.formContext || {}}
                        formData={this.state.formContext || {}}
                        onSubmit={ async ({formData}) => {
                            this.setState({
                                buttonStyle: {visibility: "visible"},
                                isLoading: true,
                                period: formData.period
                            });

                            const {onSubmitMovingOut} = await importModuleOrFallback();

                            onSubmitMovingOut(formData).then(response => {
                                this.setState({
                                    buttonStyle: {visibility: "hidden", isLoading: false},
                                    isLoading: false
                                });

                                if (response.data.includes("Message sent") || response.data.includes("Successfully terminated")) {
                                    this.clearFields();
                                    ReactDOM.render(
                                        <ExpiringAlert color="success"message={i18n.t('processes.successfulProcessStart')}/>, 
                                        document.getElementById('alert').appendChild(document.createElement("div"))
                                    );
                                } else if (response.data === 'Access denied!') {
                                    ReactDOM.render(
                                        <ExpiringAlert color="danger" message={response.data}/>, 
                                        document.getElementById('alert').appendChild(document.createElement("div"))
                                    );
                                }
                            }).catch(error => {
                                this.setState({
                                    buttonStyle: {visibility: "hidden", isLoading: false},
                                    isLoading: false
                                });
                                let message = error.response.data;

                                if (message.includes("Mismatch between provided meter register ID and expected value")) {
                                    message = 'processes.meterRegisterMismatch';
                                } else if (message.includes("Error while terminating contract with ID")) {
                                    message = 'processes.startTerminatingError';
                                } else {
                                    message = 'processes.generalError';
                                }

                                ReactDOM.render(
                                    <ExpiringAlert color="danger" message={i18n.t(message)} />, 
                                    document.getElementById('alert').appendChild(document.createElement("div"))
                                );
                            });
                        }}
                        onChange={({formData}, e) => {
                            this.setState({formContext: formData})
                        }}>
                        <div>
                            <Button type="submit" disabled={this.state.isLoading} color='primary'>{loadingIcon}{i18n.t('readings.submit')}</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button type="button" color="btn secondary" onClick={() => {this.clearFields()}}>{i18n.t('readings.formReset')}</Button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

export default MovingOut;