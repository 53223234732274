import React from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Textarea from "react-textarea-autosize";
import {SingleDatePicker} from "react-dates";
import moment from "moment";
import i18n from "../views/Pages/Login/i18n";
import { Input } from 'reactstrap';

class MeterReads extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            meterRead: "",
            readings: []
        };
    }
  
    render() {
        return null
    }
}

const uischema = {
    id : {
        "ui:widget" : "hidden"
    },
    name : {
        "ui:widget": props => {
            return (
                <div >
                    <span className="task-status icon ion-android-checkbox primary"></span>
                        <Textarea  disabled
                        className={props.schema.classes}
                        value={props.value}
                        required={props.required}
                        placeholder={props.schema.placeholder}
                        autoFocus ={true}
                        onChange={(event) => {
                            props.onChange(event.target.value)
                        }}/>
                </div>
            );
        },
        "classNames":'task-title',
    },
    type : {
        "ui:readonly": true
    },
    assignee : {
        "ui:widget" : "hidden"
    },
    customer : {
        "ui:widget" : "hidden"
    },
    status : {
        "ui:widget": props => {
            const status = props.formContext.formdata.status;
            const translation = `taskModal.status${status}`;
            return (<Input type='text' value={i18n.t(translation)} disabled />)
        },
        "ui:readonly": true
    },
    priority : {
        "ui:widget" : "hidden"
    },
    dueDate : {
        "ui:widget" : "hidden"
    },
    contact_name : {
        "ui:readonly": true
    },
    contact_number : {
        "ui:readonly": true
    },
    contact_email : {
        "ui:readonly": true
    },
    moving_premises : {
        "ui:readonly": true
    },
    change_of_od : {
        "ui:readonly": true,
        date_of_coo : {
            "ui:widget" : props => <TaskCOODate {...props}/>
        },
        meter_read: {
            "classNames": "meter-reads-wrapper",
            "ui:field": props => <MeterReads {...props} />
        }
    },
    outgoing_customer : {
        "ui:readonly": true
    },
    incomming_customer : {
        "ui:readonly": true
    },
    coo_pricing : {
        "ui:readonly": true
    },
    consumer_number: {
      "ui:readonly": true
    },
    mprn : {
        "ui:readonly": true
    },
    estate : {
        "ui:readonly": true
    },
    account_code : {
        "ui:readonly": true
    },
    closed_on : {
        "ui:readonly": true
    },
    closed_by : {
        "ui:readonly": true
    },
    description : {
        "classNames":'description',
        "ui:widget" : props => (
            <Textarea 
                disabled 
                value={props.value || ""}
                placeholder="Query Description"
                onChange={e => props.onChange(e.target.value)}
            />
        )
    },
    "ui:order": ["id", "name", "type", "moving_premises", "assignee", "customer", "status", "dueDate", "priority", 'change_of_od', 'outgoing_customer', 'incomming_customer', 'coo_pricing', 'description', 'contact_name', 'contact_number', 'contact_email', 'estate', 'consumer_number', 'mprn', 'account_code', 'closed_on', 'closed_by'],
    "classNames":'readonly',
}

class TaskCOODate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <SingleDatePicker
                date={this.props.value ? moment(this.props.value) : null}
                numberOfMonths={1}
                onDateChange={date => {
                    this.props.onChange(date ? date.format("YYYY-MM-DD") : null);
                }}
                disabled={this.props.readonly}
                displayFormat="DD/MM/YYYY"
                focused={this.state.focused}
                onFocusChange={({focused}) => this.setState({focused})}
                isOutsideRange={ (day) => false}
                id="task-coo-date"
            />
        );
    }
}

export default uischema;
