import React from 'react';
// import loadable from "@loadable/component";

import {schemaFunction} from "../schemas/task-dyce.schema";

export const getOptions = (list, value, text) => {
    // make a filter for list items and remove duplicates
    if (list.length === 1)
        return <option value={list[0][value]}>{list[0][text]}</option>

    return (
        <React.Fragment>
            <option value="">---</option>
            {list.map(item => <option value={item[value]}>{item[text]}</option>)}
        </React.Fragment>
    )
}


export const transformSchema = (schema = {}, type = 'G') => {
    return {
        ...schema,
        properties: {
            ...schema.properties,
            type: {
                ...schema.properties.type,
                anyOf: JSON.parse(JSON.stringify(schema.properties.type.anyOf[type]))
            }
        }
    }
}

export const getSchema = (schema = {}, meterPointsData = []) => {
    if (!meterPointsData || !Array.isArray(meterPointsData) || meterPointsData.length < 1 || !meterPointsData[0].purpose) {
        return {}
    }
    if (meterPointsData[0].purpose === 'Gas') {
        return transformSchema(schema, 'G') //transformSchema(schemaFunction("filter-hidden-first-one-element"), 'E')
    }
    if (meterPointsData[0].purpose === 'Electricity' || meterPointsData[0].purpose === 'Power') {
        return transformSchema(schema, 'E')
    }
    return {}
}

export const getSchemaCreteQuery = (schemaFunction = () => {}, meterPointsData = []) => {
    if (!meterPointsData || !Array.isArray(meterPointsData) || meterPointsData.length < 1 || !meterPointsData[0].purpose) {
        return {}
    }
    if (meterPointsData[0].purpose === 'Gas') {
        return transformSchema(schemaFunction("filter-hidden-first-one-element"), 'G')
    }
    if (meterPointsData[0].purpose === 'Electricity' || meterPointsData[0].purpose === 'Power') {
        return transformSchema(schemaFunction("filter-hidden-first-one-element"), 'E')
    }
    return {}
}

export const getType = (meterPointsData = []) => {
    if (!meterPointsData || !Array.isArray(meterPointsData) || meterPointsData.length < 1 || !meterPointsData[0].purpose) {
        return null;
    }
    if (meterPointsData[0].purpose === 'Gas') {
        return 'G';
    } else if (meterPointsData[0].purpose === 'Electricity' || meterPointsData[0].purpose === 'Power') {
        return 'E';
    }
    return null;
}

export const pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export const validateMeterPoints = meterPoints => (
    meterPoints.filter(x => (
        x.status === 'Live' &&
        x.energisationStatus === 'E' &&
        (x.purpose === 'Power' || x.purpose === 'Electricity') &&
        x.supplyEndDate === null &&
        x.registers &&
        Array.isArray(x.registers) &&
        x.registers.length &&
        x.registers.filter(y => (
            y.settlementType === 'S' && y.effectiveTo === null && y.meter && y.meter.type === 'N'
        )).length
    )).length
);

export const isValidEmail = userName => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(userName);

const filterActiveRegisters = (registers) => {
    const currentDate = new Date();
    return registers.filter(register => !register.effectiveTo || new Date(register.effectiveTo) >= currentDate);
};
  
export const filterMeterPointsByActiveRegisters = (meterPoint) => {
    return meterPoint.reduce((acc, curr) => {
        acc.push({
            ...curr,
            registers: filterActiveRegisters(curr.registers),
        })

        return acc;
    }, [])
};

export const getUniqueMeterPoints = (estate) => {
    let uniqueMeterPoints = new Map();
    estate.forEach(mp => {
        if(!uniqueMeterPoints.has(mp.id)) uniqueMeterPoints.set(mp.id, mp)
        else {
            if(uniqueMeterPoints.get(mp.id).endDate && uniqueMeterPoints.get(mp.id).endDate !== null && uniqueMeterPoints.get(mp.id).endDate < mp.endDate){
                uniqueMeterPoints.set(mp.id, mp);
            }
        }
    });
    return Array.from(uniqueMeterPoints.values()).filter(mp => mp);
}

export const setMovingOutSubmitData = (formData) => {
    const { companyName, customerType, contactEmail,
        firstName, lastName, moveOutDate,
        moveOutReason, description, contactPhone, reading } = formData;

    const transformedData = {
        moveOutDate: moveOutDate,
        moveOutReason: moveOutReason,
        newTenant: {
            phone: contactPhone,
            email: contactEmail,
            notes: description,
            customerType: customerType ? customerType.toLowerCase() : "",
            companyName: companyName,
            firstName: firstName,
            lastName: lastName
        },
        bpEstate: {
            meters: [{
                meterId: reading.meter.id,
                productKey: reading.mDMMeterPoint.purpose,
                finalReading: {
                    date: (new Date()).toISOString(),
                    readingValue: reading["reading" + reading.mDMMeterPoint.registers[0].id],
                    meterReadingType: reading.type || "COU_C"
                }
            }],
            bpEstateNumber: reading.mDMBPEstate.metadata.consumerNumber
        },
        properties: reading,
    }

    return axios.post("/movingOut", 
        transformedData, 
        {headers: {Authorization: `Bearer ${localStorage.token}`}}
    );
}
