import i18n from "../views/Pages/Login/i18n";

"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = exports.schemaFunction = exports.queryTypeMapping = void 0;
const queryTypeMapping = {
    G: [
        {
            type: "number",
            title: "Abrechnung",
            enum: [100]
        }, {
            type: "number",
            title: "Rechnungskorrektur",
            enum: [110]
        }, {
            type: "number",
            title: "Zählerwechsel",
            enum: [180]
        }, {
            type: "number",
            title: "Zählerstand",
            enum: [199]
        }, {
            type: "number",
            title: "Abschläge anpassen",
            enum: [210]
        }, {
            type: "number",
            title: "Stammdatenänderung",
            enum: [410]
        }, {
            type: "number",
            title: "Preise und Tarife",
            enum: [520]
        }, {
            type: "number",
            title: "Kündigung",
            enum: [530]
        }, {
            type: "number",
            title: "Bankverbindung/ Lastschrift",
            enum: [630]
        }, {
            type: "number",
            title: "Kundenportal",
            enum: [915]
        }, {
            type: "number",
            title: "Sonstiges",
            enum: [999]
        },
    ],
    E: [
        {
            type: "number",
            title: "Abrechnung",
            enum: [100]
        }, {
            type: "number",
            title: "Rechnungskorrektur",
            enum: [110]
        }, {
            type: "number",
            title: "Zählerwechsel",
            enum: [180]
        }, {
            type: "number",
            title: "Zählerstand",
            enum: [199]
        }, {
            type: "number",
            title: "Abschläge anpassen",
            enum: [210]
        }, {
            type: "number",
            title: "Stammdatenänderung",
            enum: [410]
        }, {
            type: "number",
            title: "Preise und Tarife",
            enum: [520]
        }, {
            type: "number",
            title: "Kündigung",
            enum: [530]
        }, {
            type: "number",
            title: "Bankverbindung/ Lastschrift",
            enum: [630]
        }, {
            type: "number",
            title: "Kundenportal",
            enum: [915]
        }, {
            type: "number",
            title: "Sonstiges",
            enum: [99]
        },
    ],
};

exports.queryTypeMapping = queryTypeMapping;

const schemaFunction = filtered => {
    let typeType = JSON.parse(JSON.stringify(queryTypeMapping));

    switch (filtered) {
        case "filter-hidden-first":
            typeType.G = queryTypeMapping.G.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: t.enum
                };
            });
            typeType.E = queryTypeMapping.E.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: t.enum
                };
            });
            break;
        case "filter-hidden-first-one-element":
            typeType.G = queryTypeMapping.G.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            typeType.E = queryTypeMapping.E.filter(t => !t.hidden).map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            break;
        case "filter-first":
            typeType.G = queryTypeMapping.G.map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            typeType.E = queryTypeMapping.E.map(t => {
                return {
                    type: t.type,
                    title: t.title,
                    enum: [t.enum[0]]
                };
            });
            break;
    }

    return {
        type: 'object',
        properties: {
            id: {
                type: 'string',
                title: 'Id'
            },
            description: {
                type: 'string',
                title: i18n.t('taskModal.description'),
                placeholder: 'Query Description'
            },
            type: {
                type: 'number',
                title: i18n.t('taskModal.type'),
                anyOf: typeType
            },
            customer: {
                type: 'string',
                title: 'Customer'
            },
            status: {
                type: 'string',
                title: i18n.t('taskModal.status'),
                'default': 'Open',
                'enum': ['Open', 'Closed']
            },
            priority: {
                type: 'integer',
                title: 'Priority',
                'default': 0,
                'enum': [0, 50, 100],
                enumNames: ['Low', 'Medium', 'High']
            },
            contact_name: {
                type: 'string',
                title: i18n.t('taskModal.contactName'),
                format: 'lettersOnly'
            },
            contact_number: {
                type: 'string',
                title: i18n.t('taskModal.contactNumber'),
                format: 'phone'
            },
            contact_email: {
                type: 'string',
                title: i18n.t('taskModal.contactEmail'),
                format: 'email'
            },
            estate: {
                type: 'string',
                title: i18n.t('taskModal.siteAddress')
            },
            mprn: {
                type: 'string',
                title: i18n.t('taskModal.meterPoint')
            },
            consumer_number: {
                type: 'string',
                title: i18n.t('taskModal.accountNumber')
            },
            closed_by: {
                type: 'string',
                title: i18n.t('tasks.completedBy')
            },
            closed_on: {
                type: 'string',
                title: i18n.t('tasks.completedOn')
            }
        },
        required: ["type", "description", "contact_name", "contact_number", "contact_email", "consumer_number"],
    };
};

exports.schemaFunction = schemaFunction;
const schema = schemaFunction("filter-hidden-first");
var _default = schema;
exports.default = _default;