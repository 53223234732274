import React, { useState, useEffect, useGlobal } from "reactn";
import axios from "axios";
import { Col, Row } from "reactstrap";
import Form from "react-jsonschema-form";
import commentschema from "../../schemas/taskcomment.schema.json";
import Loading from "../Loading";
import Textarea from "react-textarea-autosize";
import i18n from '../../views/Pages/Login/i18n';

const CommentWidget = (props) => {
	const { options } = props;
	const { username, newCommentValue } = options;

	return (
		<div className="comments-text-area">
			<div className="user">
				<img
					src={`${
						window.config.consul.CORE_URL ||
						(typeof CORE_URL !== "undefined" ? CORE_URL : "")
					}/avatar/${username}`}
				/>
			</div>
			<Textarea
				value={newCommentValue}
				placeholder={i18n.t("taskComment.placeholder")}
				onChange={(event) => {
					props.onChange(event.target.value);
				}}
			/>
		</div>
	);
};

const TasksComments = ({ task }) => {
	const [comments, setComments] = useState([]);
	const [newComment, setNewComment] = useState("");
	const [loading, setLoading] = useState(false);
	const [refetchComments, setRefetchComments] = useState(false);
	const [global, setGlobal] = useGlobal();

	useEffect(() => {
		if (task) {
			if (refetchComments) setRefetchComments(false);
			setLoading(true);
			axios
				.get(`/essence-services/essence-services/tasks/${task.id}/comments`, {
					headers: { Authorization: "Bearer " + localStorage.token },
				})
				.then((response) => {
					setComments(response.data);
					setLoading(false);
				});
		} else {
			setComments([]);
		}
	}, [refetchComments]);

	const postComment = (comment) => {
		setLoading(true);
		axios
			.post(
				`/essence-services/essence-services/tasks/${task.id}/comments`,
				{},
				{
					params: { comment },
					headers: {
						Authorization: "Bearer " + localStorage.token,
					},
				}
			)
			.then(() => {
				setRefetchComments(true);
				setNewComment("");
			});
	};

	const uiSchema = {
		"ui:widget": CommentWidget,
		"ui:options": {
			username: global.username,
			newCommentValue: newComment,
		},
		classNames: "comments-post-cnt",
	};

	const taskReadonly =
		!task ||
		task.variables.status === "Closed" ||
		global.permissions.includes("Users.ReadOnly") ||
		task.status === "Closed" ||
		task.status === "Cancelled";

	return task ? (
		<div className="task-comments-cnt">
			<h6>{i18n.t('taskComment.comments')}</h6>
			{loading && <Loading />}
			{comments.map((comment) => (
				<Row className="comments-list" key={comment.id}>
					<Col className="avatar">
						<img
							src={`${
								window.config.consul.CORE_URL ||
								(typeof CORE_URL !== "undefined" ? CORE_URL : "")
							}/avatar/${comment.userId}`}
						/>
					</Col>
					<Col>
						<Row>
							<Col className="comment-info">
								{comment.userId}{" "}
								<span className="date">
									{comment.timeCreated
										? new Date(comment.timeCreated).toLocaleString()
										: ""}
								</span>
							</Col>
						</Row>
						<Row className="message">
							<Col>{comment.fullMessage}</Col>
						</Row>
					</Col>
				</Row>
			))}
			{!taskReadonly && (
				<Form
					schema={commentschema}
					uiSchema={uiSchema}
					formData={newComment}
					onChange={({ formData }) => {
						setNewComment(formData);
					}}
					onSubmit={({ formData }) => {
						postComment(formData);
						return true;
					}}
				>
					<div className="buttons">
						<button type="submit" className="btn btn-primary">
							{i18n.t('taskComment.comment')}
						</button>
						<button
							type="reset"
							className="btn secondary"
							onClick={() => setNewComment("")}
						>
							{i18n.t('taskComment.cancel')}
						</button>
					</div>
				</Form>
			)}
		</div>
	) : null;
};
export default TasksComments;